
export default {
  name: 'TextBlockComponent',
  props: {
    data: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  computed: {
    textBlockCssClass() {
      return [
        this.$backgroundClassPicker(this.data.backgroundColor),
        this.$route.fullPath.includes('news') ? 'border-0' : ''
      ].filter((c) => !!c);
    }
  }
};
